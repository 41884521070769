@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;600;700;900&display=swap');

:root{
  --cinza: #353535;
  --laranja: #ff9b26;
  --fundoCinza: #131313;
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  text-decoration: none;
  background: none;
}

input, textarea:-webkit-autofill {
  -webkit-text-fill-color: var(--laranja) !important;
}

input, textarea:focus {
  outline-width: 0;
  border: 2px solid var(--laranja);
  border-radius: 5px;
}


header{
  display: flex;
  justify-content: space-between;
  padding: 0 10%;
  align-items: center;
  height: 90px;
  position: absolute;
  width: 100%;
}

.logo-header{
  width: 65px;
}

.container-titulos-header{
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titulo-header{
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #FFFFFF;
}

span.titulo-header:hover {
  border-bottom: 3px solid white;
  border-radius: 5px;
  cursor: pointer;
}






.banner-apresentacao {
  background-image: url('./assets/banner-principal.jpg');
  height: 750px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-shadow: 0px 0px 14px var(--laranja);
  padding-top: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-apresentacao{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  animation-name: BannerPrincipal;
  animation-duration: 2s;
}

#astronauta{
  width: 500px;
}

.textos-apresentacao{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 500px;
  margin-right: 60px;
}

.btn-bemvindo{
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  padding: 5px 10px;
  color: #FFFFFF;
  background: linear-gradient(90deg, rgba(251, 189, 97, 0.76) 0%, rgba(251, 189, 97, 0.5168) 0.01%, rgba(224, 133, 0, 0.76) 100%);
  border: 1px solid #FFFFFF;
  border-radius: 2px;
  cursor: default !important;
}

.titulo-apresentacao{
  font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    color: #FFFFFF;
    margin: 14px 0;
}

.texto-apresentacao{
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
}

.contato-apresentacao{
  display: flex;
  align-items: center;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 35px;
  cursor: pointer;
}

.contato-apresentacao:hover{
  font-size: 17px;
}



.seta{
  margin-left: 10px;
  width: 25px;
}



.certificados{
  padding: 75px 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1500px;
  margin: 0 auto;
}

.card-certificados{
  box-shadow: 0px 2px 15px rgb(0 0 0 / 25%);
  border-radius: 44px;
  height: 600px;
  width: 450px;
  padding: 35px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.instituicao-certificados{
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: var(--laranja);
  text-align: center;
}

.curso-certificados{
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: var(--cinza);  
  margin: 7px 0;
}

.foto-certificado{
  width: 100%;
  height: 269px;
  margin: 15px 0;
}

.descricaoCurso-certificados{
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: var(--laranja);
}
 

.carousel{
  width: 100% !important;
  height: 700px;
}



.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px
}

.carousel-control-next, .carousel-control-prev {
  width: unset !important;
}






.projeto{
  background: linear-gradient(180deg, #FFFFFF 35.93%, var(--fundoCinza) 35.94%);
  padding-bottom: 110px;
}

  
.banner-projeto{
  background-image: url('./assets/fundo-projeto.png');
  height: 600px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.titulo-projeto{
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  color: #FFFFFF;
}

.texto-projeto{
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #FFFFFF;
  max-width: 500px;
  margin-top: 25px;
}

.listagem-projeto{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 75px
}

.container-projetos{
  display: grid;
  grid-template-columns: 300px 300px 300px;
  gap: 65px;
}

.card-projetos{
  border: 5px solid #FF9B26;
  
  border-radius: 10px;
  width: 300px;
  height: 200px;
}

.card-projetos:hover{
  filter: drop-shadow(0px 2px 5px #FF9B26);
}

.card-projetos img{
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.contato{
  background-color: var(--fundoCinza);
}

.banner-contato{
  background-image: url('./assets/funco-contato.png');
  background-size: 100% 100%;
  height: 670px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 125px;
}

.titulo-contato{
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  text-align: center;
  color: #FFFFFF;
}

.container-contato{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 1100px;
}

#animacao-contato{
 width: 460px;
}

.form-contato{
  width: 400px;
  height: 370px;
  background: #FFFFFF;
  box-shadow: 0px 3px 15px #131313;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
}

.container-input-contato{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-nome-email{
  border: 2px solid #FF9B26;
  border-radius: 5px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  color: rgba(255, 155, 38, 0.65);
  width: 45%;
  padding: 2px 9px;
}

.input-nome-email::placeholder {
  color: rgba(255, 155, 38, 0.65);
}

#descricao{
  border: 2px solid #FF9B26;
  border-radius: 5px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  color: var(--laranja);
  width: 100%;
  padding: 2px 9px;
  height: 115px;
  margin: 25px 0;
}

#descricao::placeholder {
  color: var(--laranja);
  text-align: center;
}

.btn-contato{
  background: #FF9B26;
  border-radius: 3px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #FFFFFF;
  width: 110px;
  height: 35px;
}

footer{
  height: 60px;
  background: var(--fundoCinza);
  display: flex;
  justify-content: center;
  align-items: center;
}

footer span{
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #FFFFFF;
}

.container-certificados-responsivo{
  display: none !important;
}

.dropdown-toggle{
  display: none!important;
}

.dropdown-toggle::after {
  display: none!important;
}

@media (min-width:1700px){
  

  .banner-contato{
    height: 800px;
  }
}

@media (max-width:1261px){
  .container-certificados{
    display: none !important;
  }

  .container-certificados-responsivo{
    display: unset !important;
  }

  .carousel{
    width: 100% !important;
    height: 700px;
  }
  
  .carousel-item-next, .carousel-item-prev, .carousel-item.active {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 50px
  }
  
  .carousel-control-next, .carousel-control-prev {
    width: unset !important;
  }
  
}

@media (max-width:1140px){
  .container-projetos{
    display: grid;
    grid-template-columns: 300px 300px ;
    gap: 65px;
  }

  .projeto{
    background: linear-gradient(180deg, #FFFFFF 0.93%, var(--fundoCinza) 22.94%);
    padding-bottom: 110px;
  }
}

@media (max-width:1000px){
  .container-titulos-header {
    width: 60%;
  }
}

@media (max-width:860px){
  .dropdown-toggle{
    display: inline-block !important;
  }

  .esconder{
    display: none;
  }

  .logo-header {
    width: 55px;
}

  .container-titulos-header{
    justify-content: end;
  }

  .titulo-header{
    color: #131313;
    font-size: 15px;
  }

  .btn, .btn:hover {
    background-color: unset !important;
    border-color: unset !important;
    border: none;
  }

  .titulo-apresentacao {
    font-size: 25px;
  }

  .texto-apresentacao {
    font-size: 14px;
  }

  .banner-apresentacao {
    height: 500px;
  }

  .contato-apresentacao {
    font-size: 13px;
  }

  .contato-apresentacao:hover{
    font-size: 14px;
  }

  .seta {
    width: 20px;
  }

  .titulo-contato {
    font-size: 29px;
    margin-bottom: 35px;
  }

  .banner-projeto {
    height: 492px;
  }

  .curso-certificados {
    font-size: 20px;
    margin: unset;
  }

  .instituicao-certificados {
    font-size: 23px;
  }

  .card-certificados {
    width: 450px;
  }

  .card-certificados {
    height: 500px;
  }

  .foto-certificado {
    width: 100%;
    height: 193px;
  }

  .carousel {
    width: 100% !important;
    height: 595px;
  }
}

@media (max-width:720px){
  #astronauta{
    display: none;
  }

  .container-apresentacao{
    justify-content: center;
  }

  .textos-apresentacao {
    margin-right: unset;
    width: 380px;
  }
}

@media (max-width:705px){
  #animacao-contato{
    display: none;
  }

  .container-contato{
    justify-content: center;
  }

  .cardOff{
    display: none;
  }

  .container-projetos {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .carousel {
    width: 100% !important;
    height: unset;
    padding-bottom: 40px;
  }

  .container-carroussel-projeto{
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}



@media (max-width:550px){

  header {
    padding: 0 5%;
  }

  .logo-header {
    width: 50px;
  }
  
  .banner-apresentacao {
    background-size: cover;
    padding: 90px 5% 0 5%;
  }

  .contato-apresentacao{
    margin-top: 15px;
  }

  .card-certificados {
    height: 365px;
    padding: 20px 15px;
    border-radius: 17px;
  }

  .certificados {
    padding: 18px 5%;
  }

  .instituicao-certificados {
    font-size: 14px;
  }

  .curso-certificados {
    font-size: 16px;
    margin: unset;
  }

  .foto-certificado {
    width: 85%;
    height: 50%;
  }

  .descricaoCurso-certificados {
    font-size: 11px;
  }


  .banner-projeto {
    background-size: cover;
    padding: 0 5%;
    height: 350px; 
  }

  .titulo-projeto {
    font-size: 30px;
  }

  .texto-projeto {
    font-size: 16px;
  }

  .listagem-projeto {
    padding-top: 35px;
  }

  .projeto {
    padding-bottom: 35px;
  }

  .carousel-control-next, .carousel-control-prev {
    width: unset !important;
    margin-bottom: 23px;
  }

  .banner-contato {
    background-size: cover;
    height: 550px;
    padding-top: 80px;
  }

  .titulo-contato {
    font-size: 25px;
    margin-bottom: 35px;
  }

  .container-contato {
    width: 90%;
  }

  .form-contato {
    padding: 15px;
  }

  footer{
    height: 45px;
  }

  footer span {
    font-size: 16px;
  }
}

@media (max-width:450px){
  .foto-certificado {
    width: 100%;
    height: 50%;
  }
}


@keyframes BannerPrincipal {
  from{
    max-width: 100%;
  }
  to{
    max-width: 1200px;
  }
}